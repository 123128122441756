import { iField, iOrder as iOrderType } from "/app/src/models";
import { iFieldService, iOrderService, statusService } from "/app/src/services";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { Row, Col, InputNumber } from "antd";
import { Form, Input, Select } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import Box from "/app/src/components/generic/components/box";
import FormikDisabler from "/app/src/components/generic/components/formikDisabler";
import DisabledSubmitButton from "/app/src/components/generic/components/buttons/DisabledSubmitButton";
import { buildParams, getDateFormat } from "/app/src/helpers";
import TimeZoneContext from "/app/src/contexts/TimeZoneContext";
import { orderBuilderFields } from "/app/src/constants/orderBuilderFields";
import NewDetail from "./newDetail";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import NextButton from "/app/src/components/NextUi/Button";
import NextDivider from "/app/src/components/NextUi/Divider";
import { useNavigate } from "react-router-dom";

interface FormValues {
  name?: string;
  directionType?: string;
  Info1?: string;
  Info2?: string;
  Info3?: string;
  Info4?: string;
  Info5?: string;
  priority?: string;
  warehouseName?: string;
  internalName?: string;
  statusId?: number;
}

/**
 * Formats the form values to be sent to the backend API
 */
function formatForm(values: FormValues) {
  const ret = {
    name: values.name,
    directionType: values.directionType,
    Info1: values.Info1,
    Info2: values.Info2,
    Info3: values.Info3,
    Info4: values.Info4,
    Info5: values.Info5,
    priority: values.priority,
    warehouseName: values.warehouseName,
  };
  //json stringify the object
  return { data: JSON.stringify(ret), statusId: values.statusId };
}

/**
 * Displays the order details of an order created through the Order Builder App
 */
export default function OrderDetails({ iOrder }: { iOrder: iOrderType }) {
  const { t } = useTranslation();
  const { timeZone } = useContext(TimeZoneContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const goToOrder = useCallback(() => {
    navigate(`/explorer/orders/${iOrder.powerpickId}`);
  }, [iOrder.powerpickId, navigate]);
  const { data: orderFields } = useQuery({
    queryKey: ["orderFields", iOrder.integrationId],
    queryFn: () => {
      return iFieldService.getAll(
        buildParams({ integrationId: iOrder.integrationId, type: "order" }),
      );
    },
    initialData: { fields: [] },
    select: (data: { fields: iField[] }) => {
      return data.fields;
    },
  });
  const { data: statuses } = useQuery({
    queryKey: ["statuses"],
    queryFn: () => statusService.getAll(),
    select: (data) => data.statuses,
    initialData: { statuses: [] },
  });
  const saveOrder = useCallback(
    async (values: FormValues) => {
      await iOrderService
        .updateSingle(iOrder.id, formatForm(values))
        .then((response) => {
          queryClient.setQueryData(["order", iOrder.id], response);
        });
    },
    [iOrder.id, queryClient],
  );
  const deleteOrderField = useCallback(
    (fieldValue: string) => async () => {
      // Remove the field from the loadedData using Object.assign
      // because using delete operator on dynamic object is dangerous
      const { [fieldValue]: _, ...newLoadedData } = iOrder.loadedData;
      const orderData = JSON.stringify(newLoadedData);
      return await iOrderService
        .updateSingle(iOrder.id, { data: orderData })
        .then((response) => {
          queryClient.setQueryData(["order", iOrder.id], response);
        });
    },
    [iOrder.id, iOrder.loadedData, queryClient],
  );

  /**
   * Render the input fields. If the field is quantity, render an InputNumber
   * @param field
   */
  const renderInput = useCallback(
    (field, disabled) => {
      const commonProps = {
        name: field.value,
        label: field.label,
      };
      const lineField = orderFields.find((f) => f.name === field.value);
      return (
        <>
          <Col span={7} key={field.value}>
            <Form.Item {...commonProps}>
              {field.value === "quantity" ? (
                <InputNumber
                  disabled={lineField?.viewOnly || disabled}
                  name={field.value}
                  min={1}
                  size="large"
                />
              ) : (
                <Input
                  disabled={lineField?.viewOnly || disabled}
                  name={field.value}
                  suffix
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            {field.value !== "name" && (
              <NextButton
                onClick={deleteOrderField(field.value)}
                color="default"
                size="md"
                variant="bordered"
                isIconOnly
                isDisabled={
                  iOrder.orderSent || lineField?.viewOnly || lineField?.required
                }
                className="border-error-default bg-white mt-[30px]"
              >
                <IconBuilder size={18} color="#e00000" icon="Delete" />
              </NextButton>
            )}
          </Col>
        </>
      );
    },
    [iOrder.orderSent, orderFields, deleteOrderField],
  );
  const newOrderForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <FormikDisabler disabled={iOrder.orderSent} />
          <Row justify="start" gutter={16}>
            <Col span={4}>
              <div className="flex items-center space-x-2">
                <h2>{t("translation:order_details")}</h2>
                {iOrder?.powerpickId && (
                  <IconBuilder
                    icon="LinkExternal"
                    size={18}
                    onClick={goToOrder}
                  />
                )}
              </div>
            </Col>
            <Col span={6} offset={12}>
              <Form.Item name="statusId" hasFeedback={false}>
                <Select
                  name="statusId"
                  size="large"
                  disabled={iOrder?.orderSent}
                >
                  {statuses
                    .filter(
                      (status) =>
                        iOrder.orderSent ||
                        status.name !== "Sent to Power Pick",
                    )
                    .map((status) => (
                      <Select.Option key={status.id} value={status.id}>
                        {status.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <DisabledSubmitButton
                type="primary"
                size="large"
                block
                disabled={!(dirty && isValid)}
              >
                {t("translation:save")}
              </DisabledSubmitButton>
            </Col>
          </Row>
          <NextDivider className="my-3" />
          <Row justify="start" gutter={16}>
            <Col span={7}>
              <Form.Item
                name="internalName"
                label={t("translation:internal_name")}
              >
                <Input disabled name={"internalName"} suffix size="large" />
              </Form.Item>
            </Col>
            <Col span={1} />
            {orderBuilderFields
              .filter((field) => field.value in iOrder.loadedData)
              .map((field) => renderInput(field, iOrder?.orderSent))}
          </Row>
        </Form>
      ),
      [
        goToOrder,
        iOrder.loadedData,
        iOrder.orderSent,
        iOrder?.powerpickId,
        renderInput,
        statuses,
        t,
      ],
    );
  const getInitValues = useCallback(() => {
    return {
      internalName: iOrder?.name,
      creationDate: getDateFormat(
        iOrder?.creationDate,
        "YYYY-MM-DDTHH:mm:ss",
        timeZone,
      ),
      statusId: iOrder.statusId,
      ...iOrder.loadedData,
    };
  }, [iOrder, timeZone]);
  return (
    <Box>
      <Formik
        enableReinitialize
        component={newOrderForm}
        initialValues={getInitValues()}
        onSubmit={saveOrder}
      />
      {iOrder?.orderSent !== undefined && !iOrder?.orderSent && (
        <NewDetail
          iOrder={iOrder}
          availableFields={orderBuilderFields.filter((field) => {
            return !Object.keys(iOrder.loadedData).includes(field.value);
          })}
        />
      )}
    </Box>
  );
}
