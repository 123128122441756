import { Formik, FormikHelpers, FormikProps } from "formik";
import { Connection } from "/app/src/models";
import { useCallback } from "react";
import { Form, Input, InputNumber, Select, SubmitButton } from "formik-antd";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { simpleSchemaBuilder } from "/app/src/helpers";
import useSetting from "../setting";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import PasswordField from "/app/src/components/generic/components/passwordField";

interface FormValues {
  tokenDuration?: string;
  tokenDurationUnit?: string;
  login?: string;
  tokenKey?: string;
  grantType?: string;
  headerPrefix?: string;
  clientId?: string;
  clientSecret?: string;
  tokenScope?: string;
  clientAuthMethod?: string;
}

/**
 * Component to display the OAuth2 connection types.
 */
export default function OAuth2({ connection }: { connection: Connection }) {
  const {
    settingValue: tokenDuration,
    createUpdateSetting: createUpdateTokenDuration,
  } = useSetting({
    connectionId: connection.id,
    type: "tokenDuration",
  });

  const { settingValue: tokenKey, createUpdateSetting: createUpdateTokenKey } =
    useSetting({
      connectionId: connection.id,
      type: "tokenKey",
    });

  const { settingValue: login, createUpdateSetting: createUpdateLogin } =
    useSetting({
      connectionId: connection.id,
      type: "login",
    });
  const {
    settingValue: grantType,
    createUpdateSetting: createUpdateGrantType,
  } = useSetting({
    connectionId: connection.id,
    type: "grantType",
  });
  const {
    settingValue: headerPrefix,
    createUpdateSetting: createUpdateHeaderPrefix,
  } = useSetting({
    connectionId: connection.id,
    type: "headerPrefix",
  });
  const {
    setting: clientIdSetting,
    createUpdateSetting: createUpdateClientId,
  } = useSetting({
    connectionId: connection.id,
    name: "clientId",
  });

  const {
    setting: clientSecretSetting,
    createUpdateSetting: createUpdateClientSecret,
  } = useSetting({
    connectionId: connection.id,
    name: "clientSecret",
  });
  const { settingValue: tokenScope, createUpdateSetting: createUpdateScope } =
    useSetting({
      connectionId: connection.id,
      type: "tokenScope",
    });
  const {
    settingValue: clientAuthMethod,
    createUpdateSetting: createUpdateclientAuthMethod,
  } = useSetting({
    connectionId: connection.id,
    type: "clientAuthMethod",
  });

  const updateSettings = useCallback(
    async (values: FormValues) => {
      /**
       * Updates a specified field if the new value is different from the current value.
       * Constructs an update payload and calls the provided update function with it.
       *
       * @param type - The type of the field
       * @param currentValue - The current value of the field.
       * @param newValue - The new value to update the field to.
       * @param updateFunction - The function to call with the update payload.
       * @param name - Optional name to include in the update payload.
       * @returns A promise that resolves when the update function completes.
       */
      const updateField = async (
        type: string,
        currentValue: string | undefined,
        newValue: string | undefined,
        updateFunction: (values) => Promise<void>,
        name?: string,
      ) => {
        if (currentValue !== newValue) {
          const updatePayload = {
            type,
            connectionId: connection.id,
            value: newValue,
          };
          if (name) {
            updatePayload["name"] = name;
          }
          await updateFunction(updatePayload);
        }
      };

      // Handle all updates in one place
      await Promise.all([
        updateField(
          "tokenDuration",
          tokenDuration,
          `${values.tokenDuration}${values.tokenDurationUnit}`,
          createUpdateTokenDuration,
        ),
        updateField(
          "tokenKey",
          tokenKey,
          values.tokenKey,
          createUpdateTokenKey,
        ),
        updateField(
          "grantType",
          grantType,
          values.grantType,
          createUpdateGrantType,
        ),
        updateField("login", login, values.login, createUpdateLogin),
        updateField(
          "headerPrefix",
          headerPrefix,
          values.headerPrefix,
          createUpdateHeaderPrefix,
        ),
        updateField(
          "password",
          "",
          values.clientId,
          createUpdateClientId,
          "clientId",
        ),
        updateField(
          "password",
          "",
          values.clientSecret,
          createUpdateClientSecret,
          "clientSecret",
        ),
        updateField(
          "tokenScope",
          tokenScope,
          values.tokenScope,
          createUpdateScope,
        ),
        updateField(
          "clientAuthMethod",
          clientAuthMethod,
          values.clientAuthMethod,
          createUpdateclientAuthMethod,
        ),
      ]);

      return Promise.resolve();
    },
    [
      connection.id,
      createUpdateClientId,
      createUpdateClientSecret,
      createUpdateGrantType,
      createUpdateHeaderPrefix,
      createUpdateLogin,
      createUpdateScope,
      createUpdateTokenDuration,
      createUpdateTokenKey,
      createUpdateclientAuthMethod,
      tokenDuration,
      tokenKey,
      grantType,
      login,
      headerPrefix,
      tokenScope,
      clientAuthMethod,
    ],
  );
  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await updateSettings(values).then(() => {
        actions.resetForm();
      });
    },
    [updateSettings],
  );
  const { t } = useTranslation();
  const loginForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid, values }) => (
        <Form layout="vertical">
          <h3>{t("translation:token_settings")}</h3>
          <Row justify="start" gutter={16}>
            <Col span={18}>
              <Form.Item name="login" label={t("translation:login")}>
                <Input name="login" size="large" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="grantType" label={t("translation:grant_type")}>
                <Select name="grantType" size="large">
                  {[
                    { value: "password", label: "Password" },
                    {
                      value: "client_credentials",
                      label: "Client Credentials",
                    },
                  ].map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {values.grantType === "client_credentials" && (
            <Row justify="start" gutter={16}>
              <Col span={8}>
                <Form.Item name="clientId" label={t("translation:client_id")}>
                  <PasswordField
                    name="clientId"
                    passwordExists={Boolean(clientIdSetting?.id)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="clientSecret"
                  label={t("translation:client_secret")}
                >
                  <PasswordField
                    name="clientSecret"
                    passwordExists={Boolean(clientSecretSetting?.id)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="clientAuthMethod"
                  label={
                    <>
                      {t("translation:client_authentication")}
                      <IconToolTip
                        content={t(
                          "translation:client_authentication_description",
                        )}
                        className="ml-1"
                      />
                    </>
                  }
                >
                  <Select name="clientAuthMethod" size="large">
                    {[
                      { value: "header", label: t("translation:client_body") },
                      {
                        value: "header",
                        label: t("translation:client_basic"),
                      },
                    ].map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row justify="start" gutter={16}>
            <Col span={4}>
              <Form.Item
                name="headerPrefix"
                label={
                  <>
                    {t("translation:header_prefix")}
                    <IconToolTip
                      content={t("translation:header_prefix_description")}
                      className="ml-1"
                    />
                  </>
                }
              >
                <Input name="headerPrefix" size="large" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name="tokenDuration"
                label={t("translation:token_duration")}
              >
                <InputNumber
                  name="tokenDuration"
                  min={0}
                  max={1000000}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name="tokenDurationUnit"
                label={t("translation:unit_of_time")}
              >
                <Select name="tokenDurationUnit" size="large">
                  {[
                    { value: "m", label: "minutes" },
                    { value: "h", label: "hours" },
                    { value: "d", label: "days" },
                  ].map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="tokenKey"
                label={
                  <>
                    {t("translation:token_key")}
                    <IconToolTip
                      content={t("translation:token_key_description")}
                      className="ml-1"
                    />
                  </>
                }
              >
                <Input name="tokenKey" size="large" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="tokenScope"
                label={
                  <>
                    {t("translation:scope")}
                    <IconToolTip
                      content={t("translation:scope_description")}
                      className="ml-1"
                    />
                  </>
                }
              >
                <Input name="tokenScope" size="large" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
                style={{ marginTop: "30px" }}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [clientIdSetting?.id, clientSecretSetting?.id, t],
    );
  return (
    <Formik
      component={loginForm}
      initialValues={{
        login,
        tokenDuration: tokenDuration?.slice(0, -1),
        tokenDurationUnit: tokenDuration?.slice(-1),
        tokenKey: tokenKey ? tokenKey : "token",
        grantType: grantType ? grantType : "client_credentials",
        headerPrefix: headerPrefix ? headerPrefix : undefined,
        clientId: "",
        clientSecret: "",
        tokenScope: tokenScope ? tokenScope : undefined,
        clientAuthMethod: clientAuthMethod ? clientAuthMethod : "body",
      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={simpleSchemaBuilder([
        { name: "login", type: "url", required: true },
      ])}
    />
  );
}
